<template>
  <div class="plans h-100 d-flex flex-column">
    <div class="page py-0 py-sm-5 flex-grow-1 d-flex align-items-md-center py-5">
      <div class="container">
        <div class="page-container p-0 pb-5">
          <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-7">
              <div class="row">
                <div class="col-12 col-md-10">
                  <div class="subscription border mb-32 text-white">
                    <div class="row">
                      <div class="col-7 d-flex align-items-stretch">
                        <div class="subscription-info text-white position-relative
                d-flex flex-column align-items-start">
                          <p class="subscription__sale m-0 position-absolute py-1
                  px-2 border ms-0 ms-sm-5 rounded-pill" v-if="$store.state.cloaking === 'false'"
                          >
                    <span>
                      Special Offer!
                    </span>
                          </p>
                          <p class="subscription__sale m-0 position-absolute py-1
                  px-2 border ms-0 ms-sm-5 rounded-pill"
                             v-if="isProdNew && $store.state.cloaking === 'true'">
                    <span>
                      SAVE -50%
                    </span>
                          </p>

                          <p class="subscription__trial mb-2">
                            3-day trial for {{ chosenSubscription.before_timer.title[0] }}0.99
                          </p>
                          <div class="d-flex flex-column align-items-end"
                               v-if="$store.state.cloaking === 'true'">
                            <p class="subscription__new-price w-100 m-0">
                              Then {{
                                isProdNew ? chosenSubscription.before_timer.title :
                                  chosenSubscription.after_timer.title
                              }}
                            </p>
                            <p class="subscription__old-price
                    m-0 text-decoration-line-through text-white-50" v-if="isProdNew">
                              {{ chosenSubscription.after_timer.title }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-5 d-flex align-items-stretch">
                        <div class="subscription-timer d-flex
                justify-content-center align-items-center w-100">
                          <p class="m-0">
                            0{{ this.minutes }}:{{
                              this.seconds.toString().length === 1 ?
                                `0${this.seconds}` : this.seconds
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2 class="page__title mb-32 text-white h1">
                    Get top-notch math help in a flash!
                  </h2>
                </div>
              </div>
              <div class="d-grid gap-2 d-md-none col-12 mb-32">
                <button type="button" class="btn btn-light py-3 radius-8" @click="payFormClick()">
                  Continue
                </button>
              </div>
              <ul class="hero-list ps-0 mb-5 text-white">
                <li class="hero-list__item d-flex align-items-center mb-3">
                  <img src="../assets/icons/subtract.svg" alt="">
                  <span class="ms-2">
                    1 detailed step-by-step solution to your math problem
                  </span>
                </li>
                <li class="hero-list__item d-flex align-items-center mb-3">
                  <img src="../assets/icons/subtract.svg" alt="">
                  <span class="ms-2">
                    Access to secure private chat with a math expert assigned to you
                  </span>
                </li>
                <li class="hero-list__item d-flex align-items-center mb-3">
                  <img src="../assets/icons/subtract.svg" alt="">
                  <span class="ms-2">
                    Instant solution to your math tasks of any type and level
                  </span>
                </li>
                <li class="hero-list__item d-flex align-items-center">
                  <img src="../assets/icons/subtract.svg" alt="">
                  <span class="ms-2">
                    Just write your task or upload a photo of it!
                  </span>
                </li>
              </ul>
              <div class="gap-2 d-none d-md-grid col-md-4">
                <button type="button" class="btn btn-light py-3 radius-8" @click="payFormClick()">
                  Continue
                </button>
              </div>
            </div>
            <div class="d-none d-md-flex col-md-4 col-lg-5 justify-content-center">
              <div class="h-100 position-relative
              align-items-center justify-content-center d-flex">
                <img src="../assets/fire.png" alt="" class="subscription-fire position-absolute"
                     v-if="isProdNew"
                >
                <img src="../assets/mockup_v2_2x.png" alt="" class="subscription-img h-100">
              </div>
            </div>
          </div>
        </div>
        <p class="text-light pe-4 text-center" v-if="$store.state.cloaking === 'true'">
          {{ chosenSubscription.before_timer.title[0] }}0.99 charged today. If you don't cancel at
          least 24 hours before the end of the
          3-day trial period, you will automatically be charged the full price of
          {{
            isProdNew ? chosenSubscription.before_timer.title :
              chosenSubscription.after_timer.title
          }}
          . You can cancel your subscription at any time. By continuing, you indicate that
          you've read and agree to our
          <router-link to="terms" class="text-light text-decoration-none">
            Terms & Conditions,
          </router-link>
          <router-link to="privacy" class="text-light text-decoration-none">
            Privacy Policy
          </router-link>
          ,
          <router-link to="money-back" class="text-light text-decoration-none">
            Money Back
          </router-link>
          , and
          <router-link to="sub-terms" class="text-light text-decoration-none">
            Subscription Terms
          </router-link>
          .
        </p>
      </div>
    </div>
    <billing-modal :is-modal-show="isBillingModalShow"
                   :product="actualProduct"
                   :chosen-subscription="chosenSubscription"
                   @onstatechange="onBillingModalClose"
    ></billing-modal>
  </div>
</template>

<script>
import BillingModal from '@/components/common/BillingModal.vue';
// eslint-disable-next-line import/no-cycle
import { getCookie, setCookie } from '@/main';

export default {
  name: 'Plans',
  components: {
    BillingModal,
  },
  data() {
    return {
      isProdNew: true,
      minutes: getCookie('special_minutes') ? +getCookie('special_minutes') : 9,
      seconds: getCookie('special_seconds') ? +getCookie('special_seconds') : 59,
      isBillingModalShow: false,
      chosenSubscription: {
        before_timer: {
          title: '',
          uuid: '',
        },
        after_timer: {
          title: '',
          uuid: '',
        },
      },
    };
  },
  computed: {
    actualProduct() {
      return this.isProdNew
        ? this.chosenSubscription.before_timer.uuid
        : this.chosenSubscription.after_timer.uuid;
    },
  },
  methods: {
    payFormClick() {
      this.isBillingModalShow = true;
    },
    onBillingModalClose() {
      this.isBillingModalShow = false;
    },
    timer() {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.minutes === 0 && this.seconds === 0) {
            clearInterval(interval);
            resolve('complete');
            this.isProdNew = false;
          } else if (this.seconds === 0) {
            this.seconds = 59;
            this.minutes -= 1;
            setCookie('special_minutes', this.minutes);
            setCookie('special_seconds', this.seconds);
          } else {
            this.seconds -= 1;
            setCookie('special_seconds', this.seconds);
          }
        }, 1000);
      });
    },
  },
  beforeMount() {
    if (getCookie('special_plan')) {
      this.chosenSubscription = JSON.parse(getCookie('special_plan'));
      if (this.$route.query.cause && this.$route.query.cause === 'refuse') {
        this.payFormClick();
      }
    } else if (getCookie('planchik')) {
      this.$store.dispatch('getProducts',
        JSON.parse(getCookie('planchik')).before_timer.subgroup)
        .then((data) => {
          this.chosenSubscription = data.products;
          setCookie('special_plan', JSON.stringify(this.chosenSubscription));
        });
    } else {
      this.$router.push('plans');
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    this.timer()
      .then(() => {
      });
    this.$store.dispatch('clickHouseEvent', {
      event_type: 'landing_specialofferpage_view',
    });
  },
};
</script>

<style lang="scss" scoped>
.page {
  background: #5E5CE6;

  &__title {
    font-family: 'Proxima Nova', sans-serif;
    font-weight: 700;
    @media screen and (min-width: 768px) {
      font-size: 56px;
      line-height: 68px;
    }
  }

  &-continue {
    &__btn {
      background: #5E5CE6;
      color: white;
      border-radius: 28px;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.36px;
      padding-top: 16px;
      padding-bottom: 16px;
      text-transform: uppercase;

      &:hover {
        background: rgba(94, 92, 230, 0.85);
      }
    }
  }

  .subscription {
    border-radius: 6px;

    &-info {
      padding: 19px 0 19px 24px;
    }

    &-img {
      max-height: 586px;
    }

    &-fire {
      top: 0;
      left: 0;
      transform: translate(-50%, 0);
    }

    &__sale {
      bottom: 100%;
      transform: translate(0, 50%);
      background: #FC9E20;
    }

    &-timer {
      background: #FC9E20;
      font-family: 'Proxima Nova', sans-serif;
      font-weight: 700;
      font-size: 32px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &__trial {
      font-family: 'Proxima Nova', sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;

      @media screen and (max-width: 414px) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    &__new-price {
      font-family: 'Proxima Nova', sans-serif;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;

      @media screen and (max-width: 414px) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    &__old-price {
      font-family: 'Proxima Nova', sans-serif;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;

      @media screen and (max-width: 414px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  .hero-list {
    &__item {
      font-size: 18px;
    }
  }
}

.plans {
  overflow-x: hidden;
}

.mb-32 {
  margin-bottom: 32px;
}

.radius {
  &-6 {
    border-radius: 6px;
  }

  &-8 {
    border-radius: 8px;
  }
}
</style>
